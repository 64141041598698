import {
  ContextualMenu,
  DefaultButton,
  FontIcon,
  IDragOptions,
  Modal,
  PrimaryButton,
} from "@fluentui/react";
import React from "react";
import { useNavigate } from "react-router";
import { know_urls } from "../../types/userStore";
import { intesa_green } from "../../themes/themes";
import { FlexCol } from "../Flex/FlexCol";
import { FlexRow } from "../Flex/FlexRow";
import { UserProfileIcon } from "../UserProfileIcon/UserProfileIcon";
import useWindowSize from "../../utility/useWindowSize";
import "./styles.scss";
import { TbReportSearch } from "react-icons/tb";
import { GoSync } from "react-icons/go";
import { IconButton } from "@mui/material";
import { TooltipContainer } from "../TooltipContainer/TooltipContainer";
import SiteSwitch, { SBHType } from "../SiteSwitch/SiteSwitch";
import { ICustomClaims } from "../../services/user";
import { useUserContext } from "../../store/hooks/useUserContext";
type Props = {
  onTitleClick?: () => void;
  titlePosition?: "left" | "center" | "right";
  showBackArrow?: boolean;
  showUser?: boolean;
  goBack?: () => void;
  containerStyles?: React.CSSProperties;
  showBorder?: boolean;
  children?: React.ReactNode;
  showReset?: boolean;
  showAllReportIcon?: boolean;
};

export const topbarHeight = 50;

const DashboardContainer: React.FC<Props> = ({
  showAllReportIcon,
  containerStyles,
  children,
  onTitleClick,
  titlePosition,
  showBackArrow,
  showBorder,
  goBack,
  showUser,
  showReset,
}) => {
  const showBeta = true;
  const navigate = useNavigate();
  const handleGoBack = () => {
    if (goBack) {
      goBack();
    }
  };
  const { account, userClaims } = useUserContext();
  const sbhAdminClaim: ICustomClaims = { ProductId: "sbh", RuoloId: 'admin' }
  const isAdmin = userClaims.find((x) => x.ProductId === sbhAdminClaim.ProductId && x.RuoloId === sbhAdminClaim.RuoloId) !== undefined;
  const { width } = useWindowSize();
  const hardReset = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };

  const handleNavigateToAllReports = () => {
    // setSidebarOptions({
    //     isOpen: true,
    //     isModal: false,
    //     isClosable: false,
    //     width: 'l',
    //     position: 'right',
    //     transitionMovementType: 'horizontal',
    //     transitionResizeType: 'width',
    //     childrens: <AllReportsListNavigator />
    // })
    navigate(know_urls.report);
  };
  const [resetModalOpen, setResetModalOpen] = React.useState(false);
  const dragOptions: IDragOptions = {
    moveMenuItemText: "Move",
    closeMenuItemText: "Close",
    menu: ContextualMenu,
    dragHandleSelector: ".ms-Modal-scrollableContent > div:first-child",
  };
  const SBHubLogo = React.useMemo(() => {
    return (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img
        src={require("../../images/logo/LogoSmartBuildingHub.png")}
        style={{ height: 45, width: 125, objectFit: "contain" }}
      />
    );
  }, []);
  const showTitle = width && width > 1000;
  return (
    <div
      className="min-h-[100vh] text-white overflow-x-hidden max-w-[100vw]"
      style={{
        border: "1px rgb(40,65,65) solid",
        backgroundColor: "rgb(70,96,129)",
        backgroundImage:
          "linear-gradient(to bottom,rgb(70,96,129),rgb(71,85,105))",
      }}
    >
      <div
        className="flex flex-col justify-around items-center min-h-[100vh] overflow-hidden"
        style={{ ...containerStyles }}
      >
        <div
          className={`relative flex flex-row w-[100%] items-center overflow-x-hidden mt-0 ${
            showBorder ? "border-b" : ""
          }`}
          style={{
            backgroundColor: "rgb(41,52,72)",
            borderColor: intesa_green,
            overflow: "hidden",
            height: topbarHeight,
          }}
        >
          {showBackArrow && (
            <IconButton
              style={{ height: 35, width: 35, margin: "auto 10px" }}
              className="rounded-full hover:bg-transparent hover:text-xxl text-xl"
              onClick={handleGoBack}
            >
              <FontIcon
                iconName="CaretSolidLeft"
                style={{ color: intesa_green, fontWeight: "bold" }}
              />
            </IconButton>
          )}
          <figure>
            <div
              className="flex items-stretch cursor-pointer"
              onClick={() => navigate("/?view-type=product")}
            >
              {SBHubLogo}
              {showBeta && (
                <p className="beta-text text-center mt-auto mb-2">Beta</p>
              )}
            </div>
          </figure>
          {showTitle && (
            <div
              style={{
                width: 500,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                left: "calc(50vw - 250px)",
                top: 0,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: onTitleClick ? "pointer" : "default",
                }}
                onClick={onTitleClick}
              >
                <p className="text-center main-dashboard-title">SMART</p>
                <p className="text-center ml-1 main-dashboard-title">
                  BUILDING
                </p>
                <p className="text-center ml-1 main-dashboard-title">HUB</p>
              </div>
            </div>
          )}
          <div className="ml-auto" />
          {isAdmin && <SiteSwitch currentProject={SBHType.SBH}/>}
          {showAllReportIcon && (
            <TooltipContainer
              tooltip={{
                text: "Naviga alla pagina dei reports",
                id: "ALL_REPORTS_ICON_BTN",
              }}
              customContentStyles={{content:{fontSize:24}}}
            >
              <IconButton
                className="!mr-2 !w-[45px] rounded hover:bg-transparent"
                onClick={handleNavigateToAllReports}
              >
                <TbReportSearch className="text-3xl text-white" />
              </IconButton>
            </TooltipContainer>
          )}
          {showReset && (
            <TooltipContainer
              tooltip={{
                text: "Hard-Reset (cache e login)",
                id: "HARD_RESET_ICON_BTN",
              }}
              customContentStyles={{content:{fontSize:24}}}
            >
              <IconButton
                className="!mr-4 !w-[45px] rounded hover:bg-transparent"
                onClick={() => {
                  setResetModalOpen(true);
                }}
              >
                <GoSync className="text-3xl text-white" />
              </IconButton>
            </TooltipContainer>
          )}
          <Modal
            isOpen={resetModalOpen}
            dragOptions={dragOptions}
            styles={{
              main: {
                maxWidth: 800,
                height: 150,
                backgroundColor: "rgb(51,65,85)",
                borderRadius: 4,
              },
              scrollableContent: { height: "100%", width: "100%" },
            }}
            onDismiss={() => setResetModalOpen(false)}
          >
            <FlexCol
              alignItems="center"
              justifyContent="space-between"
              className="h-[100%] p-2 text-white"
            >
              <p className="text-lg">
                Confermi di voler resettare la cache e rieffettuare la login?
              </p>
              <FlexRow className="w-[100%]" justifyContent="space-between">
                <DefaultButton
                  text="Annulla"
                  onClick={() => setResetModalOpen(false)}
                />
                <PrimaryButton text="Reset" onClick={() => hardReset()} />
              </FlexRow>
            </FlexCol>
          </Modal>
          {showUser && <UserProfileIcon />}
        </div>
        {children}
      </div>
    </div>
  );
};
export { DashboardContainer };
