import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useIsMobile } from '../../utility/useIsMobile'
import moment from 'moment'
import { formatDate } from '../../utility/formatDate'
import { formatNumericValue } from '../../utility/formats'
import { FaCheck, FaTimes } from 'react-icons/fa'

type Props = {
    extraIcon?: React.ReactElement,
    noFormat?: boolean,
    extraClassnames?: string,
    bold?: boolean,
    value: string | number | null | undefined,
    align?: "center" | "right" | "left" | "inherit" | "justify",
    alert?: string | null,
    symbol?: string | null,
};

export const DetailsValue: React.FC<Props> = ({ bold, value, align, extraClassnames, noFormat, extraIcon, alert, symbol }) => {
    const { isMobile } = useIsMobile();
    const isNumeric = (str: string) => {
        return !isNaN(Number(str))
    }
    const format = (value: any) => {
        switch (typeof (value)) {
            case "string":
                if (moment(value, true).isValid()) {
                    return formatDate(value)
                }
                if (isNumeric(value)) {
                    return formatNumericValue(parseFloat(value), parseFloat(value) % 1 === 0 ? 0 : 2)
                }
                return (!value || value === "N/A") ? '' : value
            case "number":
                return formatNumericValue(value, value % 1 === 0 ? 0 : 2)
            case "boolean":
                return value ? "Sì" : "No"
            default:
                return value ?? ''
        }
    }
    return <Grid item xs={6} sm={6} md={6} lg={8} className='h-[100%] min-h-[20px] overflow-hidden' >
        <div className='flex w-[100%] items-center'>
            <Typography
                variant='body1'
                align={"left"}
                style={{ wordBreak: 'break-all' }}
                className={`${isMobile ? "!text-base" : "!text-2xl"} ${bold ? "!font-bold" : ""} w-[100%] pl-2 grow ${extraClassnames ?? ''} flex items-center`}>
                {alert === '0' && (
                    <div className="flex items-center justify-center w-6 h-6 bg-green-500 rounded-full mr-2">
                        <FaCheck className="text-white w-4 h-4" />
                    </div>
                )}
                {alert === '1' && (
                    <div className="flex items-center justify-center w-6 h-6 bg-red-500 rounded-full mr-2">
                        <FaTimes className="text-white w-4 h-4" />
                    </div>
                )}
                {noFormat ? value : format(value)} {symbol}
            </Typography>
            {extraIcon}
        </div>
    </Grid>
}