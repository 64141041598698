import { EticketSourceTypeEnum } from "./assignments"

export enum CumulativeStatusEnum {
  NotRequested = "NotRequested",
  WaitingOpening = "WaitingOpening",
  Open = "Open",
  Closed = "Closed",
  Unknown = "Unknown"
}
export interface ETicket {
    TicketSystemResponseCode: null | string,
    TicketSystemResponseMessage: null | string,
    ClosureRequestStatus: string,
    OpeningTime: null | string,
    ClosureTime: null | string,
    ClosureRequestTime: null | string,
    LastRetryTime: null | string,
    RowVersion: string,
    Id: string,
    TicketName: string,
    Description: string,       
    CRImmobile: string,        
    Causale: string,
    CodiceAmbito: string,      
    NomeSpecifica: string, 
    SourceType:EticketSourceTypeEnum,
    ExternalEventId:number,
    ExternalReferenceId:number,
    Polo:string,
    BuildingName:string,
    // ECatalogTicketId: null | string,        
    // MetricId: number,
    OpeningRequestStatus: string,
    OpeningRequestTime: null | string
    CumulativeStatus:CumulativeStatusEnum              //da calcolare in base a Opening/Closure requestStatus
  }
  export interface ISuperMetric {
    Id:number
    GroupName:string
    Name:string
    Description:string
    UoM:string
    Ambito:string
    Specifica:string
    IsECatalogRequired:boolean
    IsAssigmentRequired:boolean
    IsNotificationRequired:boolean
    RowVersion:string
}
export interface ISuperMetricValueMetadata {
  custom_link_report_detail?:string,
  faultUoM:string
}
export interface ISuperMetricValue {
    Id:number
    SuperMetricId:number
    CRImmobile:string
    ActivationValue?:number
    ActivationTime:string
    IsActive:boolean
    RowVersion:string
    Metadata:ISuperMetricValueMetadata
    BuildingName:string
    Polo:string
}
export interface IExtendedSuperMetricValue extends ISuperMetricValue{
    SuperMetric:ISuperMetric
}
export type TSuperMetricDetailPayload = {superMetric:ISuperMetric|null,superMetricValue:ISuperMetricValue | null}
export interface ISuperMetricConfiguration {
  SuperMetricId: number,
  IsAutomaticClose:boolean,
  IsECatalogRequired: boolean,
  IsAssigmentRequired: boolean,
  IsNotificationRequired: boolean,
  ClosureReason: [
    string
  ],
  OwnerMail: string,
  Priority: string,
  IsEnable: boolean,
  CanBeDelegated: boolean,
  IsEscalationRequired: boolean,
  EscalationReceiversMail: string,
  EscalationTimeInDays: number,
  Id: number,
  RowVersion: string,
  SuperMetric: ISuperMetric
  AllowedFeedback:IAssignmentFeedback[]
}
// export interface IAssignmentFeedbackType {
//   Id:number
//   Description:string
//   IsClosure:boolean
// }
export enum AssignmentFeedbackTypeEnum {
  Closure="Closure",
  Info="Info",
  Deroga="Deroga"
}
export interface IAssignmentFeedback {
  Id:number,
  SuperMetricId:number,
  Text:string,
  AssignmentFeedbackType:AssignmentFeedbackTypeEnum
}

export interface customMetric {
    Name: string,
    Value: string,
    Alert: string | null,
    Symbol: string | null
};