import React from "react";
import { useFilterPresets } from "../../store/hooks/useFilterPresets";
import { PaginationEnum, paginationContext } from "../../store/pager";
import { IColumnFilterPreset } from "../../types/general";
import { Chip, styled } from "@mui/material";
import { useIsMobile } from "../../utility/useIsMobile";
import { IColumnFilter, ODataFilterOperators } from "../../types/odata";
import { formatDateRange } from "../HeaderCell/HeaderFilterCell/DateRangeHeaderFilterCell/DateRangeHeaderFilterCell";
import { LightTooltip } from "../LightTooltip/LightTooltip";
import { useNavigate } from "react-router-dom";
import { assignments_known_urls } from "../../types/userStore";
export type FilterPresetsChipsProps = {
  enumKey: PaginationEnum;
};
const chip_common_styles = {
  padding: "2px 4px",
  marginBottom: 3,
  boxShadow: "2px 2px rgba(0,0,0,0.4)",
  ".MuiChip-label": {
    fontSize: 18,
  },
};
const StyledPresetsChip = styled(Chip)({
  ...chip_common_styles,
  backgroundColor: "rgb(38,118,1)",
  border: "1px solid rgb(180,180,180)",
  color: "white",
  ".MuiChip-deleteIcon": {
    color: "white",
    "&:hover": {
      color: "white",
    },
  },
});
const StyledFilterChip = styled(Chip)({
  ...chip_common_styles,
  backgroundColor: "rgb(245,245,245)",
  color: "black",
  border: "1px solid rgb(180,180,180)",
  ".MuiChip-deleteIcon": {
    color: "black",
    "&:hover": {
      color: "black",
    },
  },
});
export const columnNamesLangMap: { [key: string]: string } = {
  id: "id",
  polo: "polo",
  buildingname: "edificio",
  presidio: "presidio",
  "tags/tag": "Tags",
  "tags.tag": "Tags",
  tags: "Tags",
  description: "Descrizione",
  owneremail: "Owner",
  status: "Stato",
  externaleventid: "ID Evento",
  externalreferenceid: "ID Riferimento",
  isactive: "Attivo",
  openingtime: "Data apertura",
  closurereason: "Motivo Chiusura",
  unlockcolumn: "Invio Mail",
  hierarchyname: "Descrizione",
  opendate: "Data apertura",
  closedate: "Data chiusura",
  eventvalue:"Valore"
};
const odataOperatorMap: { [key in ODataFilterOperators]: string } = {
  any: "contiene",
  "all-ne": "non in",
  in: "in",
  contains: "contiene",
  eq: "uguale",
  ge: "maggiore di",
  le: "minore di",
  "ge-le": "",
  ne: "diverso da",
};
const valueNamesLangMap : { [key: string]: string } = {
  "new":"Nuovo",
  "viewed":"Visualizzato",
  "active":"Attivo",
  "assigned":"Assegnato",
  "resolved":"Risolto",
  "feedbacksent":"Feedback inviato"
};
export const FilterChips: React.FC<FilterPresetsChipsProps> = ({ enumKey }) => {
  const { presets } = useFilterPresets();
  const { state, dispatch } = React.useContext(paginationContext);
  const presetIsApplied = React.useCallback(
    (preset: IColumnFilterPreset<any>) =>
      state.pagination[enumKey].filtersPresets.find(
        (p) => p.presetName === preset.presetName
      ),
    [enumKey, state.pagination[enumKey].filtersPresets]
  );
  const appliedPresets = React.useMemo(
    () => Object.values(presets[enumKey]).filter(presetIsApplied),
    [enumKey, presetIsApplied, presets]
  );
  const appliedSimpleFilters = React.useMemo(() => {
    return state.pagination[enumKey].filters.filter(
      (f) =>
        !appliedPresets
          .map((preset) => preset.filter.columnName)
          .includes(f.columnName)
    );
  }, [appliedPresets, enumKey, state.pagination[enumKey].filters]);
  const somePresetIsApplied = Object.values(presets[enumKey]).some((preset) =>
    presetIsApplied(preset)
  );
  const handleRemovePreset = React.useCallback(
    (preset: IColumnFilterPreset<any>) =>
      dispatch({
        type: "SET_FILTERS_AND_PRESETS",
        payload: {
          key: enumKey,
          presets: appliedPresets.filter(
            (p) => p.filter.columnName !== preset.filter.columnName
          ),
          filters:appliedSimpleFilters
        },
      }),
    [appliedPresets, dispatch, enumKey]
  );
  
  const handleRemoveFilter = React.useCallback(
    (filter: IColumnFilter<any>) => {
      dispatch({
        type: "SET_FILTERS_AND_PRESETS",
        payload: {
          key: enumKey,
          filters: state.pagination[enumKey].filters.filter(
            (f) => f.columnName !== filter.columnName
          ),
          presets: state.pagination[enumKey].filtersPresets.filter(
            (p) => p.filter.columnName !== filter.columnName
          ),
        },
      });
    },
    [dispatch, enumKey, state.pagination[enumKey]]
  );
  console.log(appliedSimpleFilters)
  const formatFilterValue = (filter: IColumnFilter<any>) => {
    switch (filter.valueType) {
      case "daterange":
        return `${columnNamesLangMap[filter.columnName.toString().toLowerCase()]
          } ${formatDateRange(filter)}`;
      case "boolean":
        return filter.value
          ? columnNamesLangMap[filter.columnName.toString().toLowerCase()]
          : `Non ${columnNamesLangMap[filter.columnName.toString().toLowerCase()]
          }`;
      default:
        return `${columnNamesLangMap[filter.columnName.toString().toLowerCase()]
          } ${odataOperatorMap[filter.operator]} ${typeof(filter.value) === "string" ? valueNamesLangMap[filter.value.toLowerCase()] ?? filter.value : filter.value}`;
    }
  };
  return (
    <div className="flex max-w-[100%] flex-wrap items-center">
      {appliedPresets.map((preset, i) => (
        <LightTooltip
          placement="bottom"
          title={preset.help ?? preset.presetName}
        >
          <StyledPresetsChip
            style={{ marginLeft: i > 0 ? 3 : 0 }}
            onDelete={() => handleRemovePreset(preset)}
            label={preset.presetName}
          />
        </LightTooltip>
      ))}
      {appliedSimpleFilters.map((filter, i) => (
        <StyledFilterChip
          onDelete={() => handleRemoveFilter(filter)}
          style={{ marginLeft: i > 0 || somePresetIsApplied ? 3 : 0 }}
          label={`${formatFilterValue(filter)}`}
        />
      ))}
    </div>
  );
};
