import { AssignmentFeedbackTypeEnum } from "./etickets"

export enum AssignmentStatusEnum {
    StandBy = "StandBy",
    New = "New",
    Viewed = "Viewed",
    Assigned = "Assigned",
    Resolved = "Resolved",
    FeedbackSent="FeedbackSent"
}
export enum EticketSourceTypeEnum {
    Metric = "Metric",
    SuperMetric = "SuperMetric",
}
export interface ITag {
    Tag: string
}
export interface IAssignmentFeedback {
    AssignmentId: number
    Feedback: string
    FeedbackType: AssignmentFeedbackTypeEnum
    FeedbackTypeId: number
    Id: number
    InsertedBy: string
    TTLDays: number
    TimeStamp: string
}
export type TAssignment = {
    Id: number,
    Description?: string,
    OpeningTime: string,
    ExpirationTime: string,
    ClosureTime: string,
    ClosureReason: string,
    EscalationNotificationTime: string,
    Notes: string,
    RowVersion: string,
    ConfigurationId: number,
    BuildingName: string,
    Polo: string,
    MailSent:boolean,
    // AlertId: number,
    SourceType: EticketSourceTypeEnum,
    ExternalEventId: number,
    ExternalReferenceId: number,
    Status: AssignmentStatusEnum,
    LastStatusChangingTime: string,
    OwnerEmail: string,
    UnlockColumn?: string
    Tags: ITag[]
    Presidio: string
    CRImmobile: string
    AssignmentFeedbacks: IAssignmentFeedback[]
    EventValue:undefined|string|number
}

export interface IAssignmentConfiguration {
    OwnerMail: string
    MetricId: number,
    ClosureReason: string[],
    IsAutomaticClose: boolean
}