import { Label } from '@fluentui/react';
import { Switch } from '@mui/material';
import React from 'react';

export enum SBHType {
    SBH = 'SBH',
    SBHR = 'SBHR'
}

type Props = {
    currentProject: SBHType
};

export const SiteSwitch: React.FC<Props> = ({currentProject}) => {
  const handleSwitch = (checked: any) => {
    const sbhUrl = 'https://smartbp07.azurewebsites.net/';  
    const sbhRestyleUrl = 'https://smartbp09.azurewebsites.net/';
    const redirectUrl = checked ? sbhRestyleUrl : sbhUrl;
    window.location.href = redirectUrl;
  };

  return (
    <div className="flex items-center space-x-2">
      <Label htmlFor="project-switch" className="text-sm font-medium text-white">
        {currentProject === SBHType.SBH ? 'Prova il nuovo SBH' : 'Passa al vecchio SBH'}
      </Label>
      <Switch
        color="default"
        id="project-switch"
        defaultChecked={currentProject === SBHType.SBHR}
        checked={currentProject === SBHType.SBHR}
        onClick={() => handleSwitch(currentProject === SBHType.SBH)}
      />
    </div>
  );
};

export default SiteSwitch;